import Typography from "@mui/material/Typography";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { makeStyles, styled } from "@mui/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  aiTweet: {
    backgroundColor: "aliceblue !important",
    // backgroundImage:
    //   "linear-gradient(45deg, rgba(106,112,255,0.2), rgba(255,138,250,0.2)) !important",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "12px !important",
    boxShadow: "none !important",
    "&:hover": {
      // border: "1px solid transparent",
      cursor: "pointer",
      // boxShadow: "5px 5px 30px #ccd3d9, -5px -5px 30px #ffffff !important",
    },
  },
  accordionSummary: {},
  primaryButton: {
    borderRadius: "500px !important",
    backgroundColor: "rgba(106,112,255,0.2) !important",
    color: "#6A70FF !important",
    fontSize: "1rem !important",
  },
  accordionIcon: {},
  tweetOutputArea: {
    backgroundColor: "#eef2f6",
  },
  tweetGenerator: {
    borderRadius: "15px !important",
    padding: "3px",
    backgroundImage: "linear-gradient(45deg, #6A70FF, #FF8AFA) !important",
  },
}));

const Progress = styled(LinearProgress)(({ theme }) => ({
  height: "15px !important",
  borderRadius: 500,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#eef2f6",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 500,
    backgroundColor: "rgba(106,112,255,0.6)",
  },
}));

export default function AIAccordion() {
  const classes = useStyles();
  const appState = useSelector((state) => state);
  const [tweet, setTweet] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const getTweet = () => {
    setTweet("");
    setFirstLoad(false);
    fetch(`https://sentient.azurewebsites.net/new_tweet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session_id: appState.sessionID }),
    })
      .then((response) => response.json())
      .then((data) => setTweet(data.content))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Card elevation={0} className={classes.aiTweet}>
        <CardContent style={{ padding: "16px" }}>
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            onClick={() => {
              if (firstLoad) {
                getTweet();
              }
            }}
          >
            {firstLoad ? (
              <>
                <Typography
                  variant={"body1"}
                  style={{ color: "rgba(63,61,86,0.9)" }}
                >
                  Ask AI to generate a Tweet
                </Typography>
                <svg width={0} height={0}>
                  <linearGradient id="linearColors" x1={0} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="#6A70FF" />
                    <stop offset={1} stopColor="#FF8AFA" />
                  </linearGradient>
                </svg>
                <AutoFixHighIcon
                  sx={{ fontSize: 24, fill: "url(#linearColors)" }}
                />
              </>
            ) : (
              <>
                <Stack spacing={1} width={"90%"}>
                  {tweet !== "" ? (
                    <Typography
                      variant={"body1"}
                      style={{ color: "rgba(63,61,86,0.9)" }}
                    >
                      {tweet}
                    </Typography>
                  ) : (
                    <Typography
                      variant={"body1"}
                      style={{ color: "rgba(63,61,86,0.9)" }}
                    >
                      Generating Tweet...
                    </Typography>
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  direction="row"
                  width={"10%"}
                  justifyContent={"flex-end"}
                >
                  {tweet !== "" ? (
                    <RefreshIcon
                      onClick={getTweet}
                      style={{
                        fontSize: "24px",
                        padding: "0px",
                        borderRadius: "500px",
                        backgroundColor: "rgba(106,112,255,0.2)",
                        color: "#6A70FF",
                      }}
                    />
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <Progress variant={"indeterminate"} />
                    </Box>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
