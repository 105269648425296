import React, { useState, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import { HomePage } from "./Components/home";
import { BasicDashboard } from "./Components/application/basicDashboard/dashboard";
import { SentimentDashboard } from "./Components/application/SentimentDashboard/dashboard";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SideNav from "./Components/application/sideNav";
import Filters from "./Components/application/filters";
import {
  setNotificationContent,
  setSentimentData,
  setSentimentMessage,
  setSentimentTimer,
  setSessionID,
  setTrendData,
  setTrendMessage,
  setTrendTimer,
} from "./Redux/actions/sentientActions";
import { isEmpty } from "./utils/utils";
import { PreLoad } from "./Components/preLoad";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const appState = useSelector((state) => state);
  const dispatch = useDispatch();

  const [showNotification, setShowNotification] = useState(false);
  const [serverUp, setServerUp] = useState(false);

  const closeNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowNotification(false);
  };
  const wakeUpServer = () => {
    fetch(`https://sentient.azurewebsites.net/warm_up`, {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "Content-Type": "application/json",
      },
    }).then((r) => setServerUp(true));
  };

  useEffect(
    () => setShowNotification(!!appState.notification.msg),
    [appState.notification.id, appState.notification.msg]
  );

  useEffect(() => {
    wakeUpServer();
  }, []);

  useEffect(() => {
    if (serverUp) {
      fetch(`https://sentient.azurewebsites.net/create_melody`, {
        method: "GET",
        headers: {
          "access-control-allow-origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => dispatch(setSessionID(data.id)));
    }
  }, [dispatch, serverUp]);

  useEffect(() => {
    const trendInterval = setInterval(() => {
      if (appState.query !== "") {
        fetch(`https://sentient.azurewebsites.net/message_trend`, {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ session_id: appState.sessionID }),
        })
          .then((response) => response.json())
          .then((data) =>
            dispatch(setTrendMessage({ msg: data.message, value: data.value }))
          )
          .catch((error) => console.log(error));
      }
    }, 3000);
    dispatch(setTrendTimer(trendInterval));

    const sentimentInterval = setInterval(() => {
      if (appState.query !== "") {
        fetch(`https://sentient.azurewebsites.net/message_sentiment`, {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ session_id: appState.sessionID }),
        })
          .then((response) => response.json())
          .then((data) =>
            dispatch(
              setSentimentMessage({ msg: data.message, value: data.value })
            )
          )
          .catch((error) => console.log(error));
      }
    }, 3000);
    dispatch(setSentimentTimer(sentimentInterval));

    const trendAnalysisInterval = setInterval(() => {
      if (appState.query !== "") {
        fetch(`https://sentient.azurewebsites.net/trends`, {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            handle: appState.query,
            session_id: appState.sessionID,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!("msg" in data)) {
              dispatch(setTrendData(data));
              if (!isEmpty(data) && data["analysis"]) {
                dispatch(
                  setNotificationContent({
                    type: "success",
                    msg: "Analytics Dashboard is Ready!",
                    id: Math.random(),
                  })
                );
              }
              clearInterval(trendInterval);
              clearInterval(trendAnalysisInterval);
            }
          })
          .catch((error) => console.log(error));
      }
    }, 5000);

    const sentimentAnalysisInterval = setInterval(() => {
      if (appState.query !== "") {
        fetch(`https://sentient.azurewebsites.net/sentiment`, {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            handle: appState.query,
            session_id: appState.sessionID,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!("msg" in data)) {
              dispatch(setSentimentData(data));
              if (!isEmpty(data) && data["analysis"]) {
                dispatch(
                  setNotificationContent({
                    type: "success",
                    msg: "Sentiment Dashboard is Ready!",
                    id: Math.random(),
                  })
                );
              }
              clearInterval(sentimentInterval);
              clearInterval(sentimentAnalysisInterval);
            }
          })
          .catch((error) => console.log(error));
      }
    }, 5000);
  }, [appState.query, dispatch]);

  return (
    <>
      {serverUp ? (
        <>
          {appState.view.homeState ? (
            <HomePage />
          ) : appState.view.trendDashboard ? (
            <BasicDashboard
              handle={appState.query}
              data={appState.trendData}
              message={appState.trendMessage}
            />
          ) : (
            <SentimentDashboard
              handle={appState.query}
              data={appState.sentimentData}
              message={appState.sentimentMessage}
            />
          )}
          {appState.view.homeState || <SideNav />}
          <Filters open={appState.queryFilterView} />
        </>
      ) : (
        <PreLoad />
      )}
      <Snackbar
        open={showNotification}
        autoHideDuration={2500}
        onClose={closeNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={closeNotification}
          severity={appState.notification.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {appState.notification.msg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
