import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

const useStyles = makeStyles((theme) => ({
  unavailableCard: {
    backgroundColor: "aliceblue !important",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "none !important",
    borderRadius: "12px !important",
    "& .MuiCardHeader-title": {
      fontSize: "1rem !important",
    },
  },
  unavailableTitle: {
    backgroundColor: "transparent",
    color: "rgba(63,61,86,0.9)",
  },
}));

export default function UnavailableCard() {
  const classes = useStyles();
  return (
    <Card className={classes.unavailableCard}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h4" style={{ color: "rgba(63,61,86,0.9)" }}>
            Not Found
          </Typography>
          <ErrorRoundedIcon
            style={{
              fontSize: "32px",
              padding: "4px",
              borderRadius: "500px",
              color: "#F476EF",
              backgroundColor: "rgba(244,118,239,0.2)",
            }}
          />
        </Stack>
        <Typography variant="body2">
          Data for this Card is Unavailable
        </Typography>
      </CardContent>
    </Card>
  );
}
