import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import leaves_1 from "../../../Assets/Leaves_Sketch.png";
import rightIllustration from "../../../Assets/Right Filler Illustration.png";
import { NavBar } from "../../navbar";
import NewQueryButton from "../../fab";
import DoughnutCard from "./doughnutCard";
import ChartCard from "./chartCard";
import RadarCard from "./radarCard";
import ProfileCard from "../basicDashboard/profileCard";
import { PreLoad } from "../loadingScreen";
import { indexOfMax, isEmpty } from "../../../utils/utils";
import InfoTrendCard from "../basicDashboard/infoTrendCard";
import InfoCard from "../basicDashboard/infoCard";
import { InvalidAccount } from "../invalidAccountScreen";
import UnavailableCard from "../unavailableCard";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "aliceblue",
  },
  dashboardContainer: {
    backgroundColor: "#eef2f6",
    // border: "2px solid rgba(0, 0, 0, 0.1)",
    width: "100vw",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "flex-start",
    zIndex: 1,
    padding: "16px",
    borderRadius: "12px !important",
    marginTop: "16px !important",
    // marginBottom: "24px !important",
    top: "10vh",
    bottom: "24px",
    position: "fixed",
    overflowY: "scroll",
  },
  backgroundEffect1: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  backgroundEffect2: {
    position: "fixed",
    top: 0,
    right: 0,
    height: "20vh",
  },
  backgroundEffect3: {
    position: "fixed",
    bottom: 0,
    right: 0,
    opacity: 1,
    // filter: "blur(1px)",
  },
  backgroundEffect4: {
    position: "fixed",
    bottom: 0,
    left: 0,
    opacity: 1,
    // filter: "blur(1px)",
  },
}));

export const SentimentDashboard = ({ handle, data, message }) => {
  const classes = useStyles();

  return (
    <>
      {isEmpty(data) ? (
        <PreLoad content={message.msg} progress={message.value} />
      ) : data["analysis"] ? (
        <Grid container className={classes.dashboard}>
          <img src={leaves_1} alt="" className={classes.backgroundEffect1} />
          {/*<img src={leaves_2} alt="" className={classes.backgroundEffect2} />*/}
          <img
            src={rightIllustration}
            alt=""
            className={classes.backgroundEffect3}
          />
          <NavBar />
          <NewQueryButton />
          <Grid
            item
            container
            xs={9}
            className={`${classes.dashboardContainer} customNav`}
            spacing={3}
          >
            <Grid
              item
              container
              xs={12}
              spacing={3}
              style={{
                paddingLeft: 0,
                paddingTop: 0,
                position: "sticky !important",
              }}
            >
              <Grid item xs={12}>
                <ProfileCard handle={handle} />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={3}
              style={{ paddingLeft: 0, paddingTop: 0 }}
            >
              <Grid item xs={4}>
                {data["positive_trend"] === false ? (
                  <UnavailableCard />
                ) : (
                  <InfoTrendCard
                    title={
                      data["positive_trend"]
                        ? "Avg. Positive Score"
                        : "Data not Available"
                    }
                    content={
                      data["positive_trend"]
                        ? data["positive_trend"]["mean"]
                        : "404"
                    }
                    inference={
                      data["positive_trend"]
                        ? data["positive_trend"]["inference"]
                        : null
                    }
                    data={data["positive_trend"]["trend"]}
                    // forecast={data["positive_trend"]["forecast"]}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {data["neutral_trend"] === false ? (
                  <UnavailableCard />
                ) : (
                  <InfoTrendCard
                    title={
                      data["neutral_trend"]
                        ? "Avg. Neutral Score"
                        : "Data not available"
                    }
                    content={
                      data["neutral_trend"]
                        ? data["neutral_trend"]["mean"]
                        : "Unavailable"
                    }
                    inference={
                      data["neutral_trend"]
                        ? data["neutral_trend"]["inference"]
                        : null
                    }
                    data={data["neutral_trend"]["trend"]}
                    // forecast={data["neutral_trend"]["forecast"]}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {data["negative_trend"] === false ? (
                  <UnavailableCard />
                ) : (
                  <InfoTrendCard
                    title={
                      data["negative_trend"]
                        ? "Avg. Negative Score"
                        : "Data not Available"
                    }
                    content={
                      data["negative_trend"]
                        ? data["negative_trend"]["mean"]
                        : "Unavailable"
                    }
                    inference={
                      data["negative_trend"]
                        ? data["negative_trend"]["inference"]
                        : null
                    }
                    data={data["negative_trend"]["trend"]}
                    // forecast={data["negative_trend"]["forecast"]}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3} style={{ paddingLeft: 0 }}>
              <Grid item xs={6}>
                {data["emotion_distribution"] === false ? (
                  <UnavailableCard />
                ) : (
                  <DoughnutCard
                    data={data["emotion_distribution"]["trend"]}
                    labels={data["emotion_distribution"]["labels"]}
                    secondaryLabel={"Emotion"}
                    title={"Emotion distribution in comments"}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {data["polarity_distribution"] === false ? (
                  <UnavailableCard />
                ) : (
                  <DoughnutCard
                    data={data["polarity_distribution"]["trend"]}
                    labels={data["polarity_distribution"]["labels"]}
                    secondaryLabel={"Polarity"}
                    title={"Polarity distribution in comments"}
                  />
                )}
              </Grid>
              <Grid item container xs={2} spacing={3} alignContent="flex-start">
                <Grid item xs={12}>
                  {data["polarity_distribution"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <InfoCard
                      content={
                        data["polarity_distribution"]["percentage"] + "%"
                      }
                      inference={data["polarity_distribution"]["inference"]}
                      message={`People show ${data["polarity_distribution"]["dominant_polarity"]} polarity to your tweets`}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {data["emotion_distribution"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <InfoCard
                      content={data["emotion_distribution"]["percentage"] + "%"}
                      inference={data["emotion_distribution"]["inference"]}
                      message={`People show ${data["emotion_distribution"]["dominant_emotion"]} emotion to your tweets`}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3} style={{ paddingLeft: 0 }}>
              <Grid item xs={7}>
                {data["time_trends"] === false ? (
                  <UnavailableCard />
                ) : (
                  <RadarCard
                    labels={[
                      "Midnight (00 - 04 hours)",
                      "Early Morning (04 - 08 hours)",
                      "Morning (08 - 12 hours)",
                      "Afternoon (12 - 16 hours)",
                      "Evening (16 - 20 hours)",
                      "Night (20 - 00 hours)",
                    ]}
                    secondaryLabels={data["time_trends"]["emotion_labels"]}
                    data={[
                      data["time_trends"]["emotions"]["Happy"],
                      data["time_trends"]["emotions"]["Surprise"],
                      data["time_trends"]["emotions"]["Angry"],
                      data["time_trends"]["emotions"]["Sad"],
                      data["time_trends"]["emotions"]["Fear"],
                    ].filter(Boolean)}
                    size={65}
                    title={"Aggregate Time-Emotion Analysis"}
                  />
                )}
              </Grid>
              <Grid item container xs={5} spacing={3} alignContent="flex-start">
                <Grid item xs={12}>
                  {data["time_trends"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <InfoCard
                      content={
                        [
                          "Midnight",
                          "Early Morning",
                          "Morning",
                          "Afternoon",
                          "Evening",
                          "Night",
                        ][
                          indexOfMax([
                            data["time_trends"]["emotions"]["Happy"],
                            data["time_trends"]["emotions"]["Surprised"],
                          ])
                        ]
                      }
                      inference={1}
                      message="Is the best time to tweet"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {data["time_trends"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <ChartCard
                      labels={[
                        "Midnight",
                        "Early Morning",
                        "Morning",
                        "Afternoon",
                        "Evening",
                        "Night",
                      ]}
                      secondaryLabels={data["time_trends"]["polarity_labels"]}
                      data={[
                        data["time_trends"]["polarity"]["Positive"],
                        data["time_trends"]["polarity"]["Negative"],
                      ].filter(Boolean)}
                      title="Polarity vs Time analysis"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3} style={{ paddingLeft: 0 }}>
              <Grid item container xs={5} spacing={3}>
                <Grid item xs={12}>
                  {data["word_trends"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <ChartCard
                      labels={data["word_trends"]["t10_words"]}
                      secondaryLabels={["Number of Occurrences"]}
                      data={[data["word_trends"]["t10_word_counts"]]}
                      title="Top words in Comments"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {data["word_trends"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <ChartCard
                      labels={data["word_trends"]["t10_bigrams"]}
                      secondaryLabels={["Number of Occurrences"]}
                      data={[data["word_trends"]["t10_bigram_counts"]]}
                      title="Top Bi-Grams in comments"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={7}
                spacing={3}
                alignContent={"flex-start"}
              >
                <Grid item xs={12}>
                  {data["word_trends"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <InfoCard
                      content={data["word_trends"]["t10_bigrams"][0]}
                      inference={1}
                      message="is the most frequently used bigram in the comments"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {data["commenter_trends"] === false ? (
                    <UnavailableCard />
                  ) : (
                    <RadarCard
                      labels={[
                        "Happy",
                        "Angry",
                        "Surprise",
                        "Sad",
                        "Fear",
                        "pos",
                        "neg",
                      ]}
                      secondaryLabels={Object.keys(data["commenter_trends"])}
                      data={Object.values(data["commenter_trends"])}
                      size={65}
                      title={"Aggregate Commenter-Emotion Analysis"}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <InvalidAccount handle={handle} />
      )}
    </>
  );
};
