export const colors = [
  ["#87BF10", "rgba(135,191,16,0.1)", "#87BF10"],
  ["#F476EF", "rgba(244,118,239,0.1)", "#F476EF"],
  ["#6A70FF", "rgba(106,112,255,0.1)", "#6A70FF"],
  ["#896363", "rgba(137,99,99,0.1)", "#896363"],
  ["#ff6961", "rgba(255,105,97,0.1)", "#ff6961"],
];

export const colors_dark = [
  ["#87BF10", "rgba(135,191,16,0.5)", "#87BF10"],
  ["#F476EF", "rgba(244,118,239,0.5)", "#F476EF"],
  ["#6A70FF", "rgba(106,112,255,0.5)", "#6A70FF"],
  ["#896363", "rgba(137,99,99,0.5)", "#896363"],
  ["#ff6961", "rgba(255,105,97,0.5)", "#ff6961"],
];
