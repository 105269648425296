export const ActionTypes = {
  SET_VIEW: "VIEW CHANGED",
  SET_NOTIFICATION: "NOTIFICATION ADDED",
  SET_QUERY: "QUERY ADDED",
  QUERY_FILTERS_DIALOG: "QUERY FILTERS DIALOG OPENED",
  SET_TREND_MESSAGE: "TREND MESSAGE SET",
  SET_TREND_DATA: "TREND DATA SET",
  SET_SENTIMENT_MESSAGE: "SENTIMENT MESSAGE SET",
  SET_SENTIMENT_DATA: "SENTIMENT DATA SET",
  SET_TREND_TIMER: "TREND TIMER SET",
  SET_SENTIMENT_TIMER: "SENTIMENT TIMER SET",
  SET_SESSION_ID: "SESSION INITIALIZED",
};
